.send_message_file {
    display: grid;
    margin-top: 25px;
    text-align: left;
    max-width: 320px;
    justify-self: right;
    border-radius: 10px;
    background: #83e2ff;
    padding: 10px 10px 6px;

    button {
        color: #333;
        margin-top: 5px;
        text-align: left;
        background: none;
    }

    a {
        color: #000;
        margin-top: 5px;
        font-size: 12px;
        font-style: normal;
        font-optical-sizing: auto;
        font-family: "Montserrat", sans-serif;
    }
}

.receiver_message_file {
    display: grid;
    text-align: left;
    max-width: 320px;
    margin-top: 25px;
    justify-self: left;
    border-radius: 10px;
    background: #E9E9EB;
    padding: 10px 10px 6px;

    button {
        color: #333;
        margin-top: 5px;
        text-align: left;
        background: none;
    }

    a {
        color: #000;
        margin-top: 5px;
        font-size: 12px;
        font-style: normal;
        font-optical-sizing: auto;
        font-family: "Montserrat", sans-serif;
    }
}