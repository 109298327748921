.chat__receiver_message_img {
    width: 250px;
    margin-top: 25px;
    justify-self: left;

    a {
        color: #b6b5b5;
    }
}

.chat__send_message_img {
    width: 250px;
    margin-top: 25px;
    justify-self: right;

    a {
        color: #b6b5b5;
    }
}