.service_info {
    height: 100vh;
    display: grid;
    .service_info__btn_back {
        top: 20px;
        left: 15px;
        position: absolute;
        svg {
            color: #5f5f5f;
            font-size: 18px;
        }
    }
    h1 {
        font-size: 26px;
        text-align: center;
    }
    img {
        width: 100%;
        margin-top: 45px;
        border-radius: 50%;
    }
    p {
        font-size: 22px;
        font-weight: 600;
        margin-top: 40px;
        text-align: center;
    }
    button {
        margin-top: 25px;
    }
}