.services_pay {
    margin-top: 20px;
    .services_pay__price {
        h2 {
            color: #000;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }
    }
    .services_pay__promocode {
        p {
            color: #000;
            font-size: 16px;
            margin-top: 15px;
            font-weight: 500;
        }
        form {
            display: flex;
            margin-top: 15px;
            align-items: center;
            input {
                width: 100%;
                padding: 14px;
                border-radius: 10px 0 0 10px;
                border: 1px solid #858585;
            }
            button {
                height: 46px;
                padding: 14px;
                color: #fff;
                background: rgb(13, 60, 101);
            }
        }
    }
    .services_pay__btn {
        button {
            width: 100%;
            color: #fff;
            display: flex;
            font-size: 20px;
            margin-top: 30px;
            font-weight: 700;
            font-weight: 500;
            line-height: 22px;
            padding: 14px 24px;
            border-radius: 14px;
            align-items: center;
            border-radius: 30px;
            background: #8DE719;
            justify-content: center;
        }
    }
}