.main_btn {
    width: 100%;
    color: #fff;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    font-weight: 500;
    line-height: 22px;
    padding: 14px 24px;
    border-radius: 14px;
    align-items: center;
    border-radius: 30px;
    background: #8DE719;
    justify-content: center;
}

.main_back_btn {
    position: absolute;
    left: 10px;
    top: 20px;

    svg {
        color: #707070;
        font-size: 18px;
    }
}